import './la-cousinade.scss';
import * as React from 'react';
import Photo from './gallery/photo';
import googleAnalyticsService from '../services/google-analytics.service';

export default function LaCousinade() {
  return (
    <section className="laCousinade">
      <aside className="laCousinade__aside">
        <Photo
            extraClass="laCousinade__photoCoenraad"
            href="#/fotogalerij/foto-108"
            src="./images/coenraad-boom.jpg"
            variation="cover"
            analytics={googleAnalyticsService.getSendEventFunction('la cousinade', 'click photo', 'coenraad aan boom')}
          />
      </aside>
      <article className="laCousinade__article">
        <h2>
          Over de Cousinade
        </h2>
        <p>
          Een Cousinade is de Franse manier om als familie gezellig bij elkaar te zijn en om bij te praten over de dingen van het afgelopen jaar. Cousinade komt van het woord Cousin(e), neef/nicht. De Fransen hangen erg aan het ouderlijk huis, en het land, <em>le terroir</em>, waar ze ooit vandaan kwamen. Ze zullen daarom niet snel het ouderlijk huis/ erf opgeven of verkopen. Volgens dezelfde esprit nodigen we jullie daarom uit om een paar dagen bij elkaar te zijn!
        </p>
        <h3>
          Over La Ripatte
        </h3>
        <Photo src="./images/la-ripatte-1976.jpg" extraClass="laCousinade__photoLaRippe" />
        <p>
          <i>La Ripatte</i> is de kadastrale naam van het stukje land waar ons huis op staat. La Ripatte of La Rippe zijn oude benamingen voor stukjes woeste grond. Onze boerderij is in 1886 gebouwd en stond op een armoedig kaal stukje land. Op deze foto kun je zien hoe de boerderij er in 1976 bij stond:
        </p>
        <p>
          In de tussentijd is er heel wat veranderd. Jullie kunnen zien, dat het een heel erg fijne plek geworden is, die wij graag met jullie willen delen tijdens het cousinade-weekend!
        </p>
        <h3>
          Overnachten
        </h3>
        <p>
          Jullie kunnen bij ons op het terrein kamperen met tent, camper en caravan, er is plek genoeg. We hopen, dat de weergoden ons goedgezind zijn!
        </p>
        <p>
          Mocht je liever in een stevig bed slapen dan kan dat ook. Er zijn hotels  of Chambre-d’hôtes, dicht bij ons in de buurt. In Cuisery is een heel aangenaam hotel,  waar we we met familie vaak met plezier uit eten gaan: “Hostellerie Bressane” en in Tournus is “Hotel Greuze” aan te bevelen, ongeveer 20 km rijden van hier.
        </p>
      </article>
    </section>
  );
}
