import './text-area.scss';
import * as React from 'react';
import FormField from './form-field';
import { BaseInputProps } from '../signup/sign-up-form';

export interface TextAreaProps extends BaseInputProps {
  handleChange?: (name: string, value:string) => void;
  placeholder?: string;
  size?: 'small';
};

export default function TextArea(props:TextAreaProps) {
  return props.isVisible() ? (
    <FormField
      name={props.name}
      label={props.label}
      required={props.required}
      hasError={props.hasError}
      errorMessage={props.errorMessage}
    >
      <textarea className={props.size
          ? `textArea textArea--${props.size} ${props.hasError ? 'textArea--hasError' : ''}`
          : `textArea ${props.hasError ? 'textArea--hasError' : ''}`
        }
        id={`field-${props.name}`}
        name={props.name}
        value={props.value || ''}
        onChange={(e) => props.handleChange
          ? props.handleChange(props.name, e.target.value)
          : false}
        required={props.required}
        ref={props.refObj}
        placeholder={props.placeholder}
      />
    </FormField>
  ) : null;
}
