import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import Main from './components/main';
import Header from './components/header';
import googleAnalyticsService from './services/google-analytics.service';

const renderApp = (element:Element) => {
  ReactDOM.render(
    <div id="la-ripatte">
      <Header />
      <Main />
    </div>,
    element
  );
};

(function boot() {
  const container = document.createElement('div');
  container.setAttribute('id', 'cousinade');
  document.body.appendChild(container);
  renderApp(container);

  googleAnalyticsService.pageview();
})();


