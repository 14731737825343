import './gallery-button.scss';
import * as React from 'react';

export namespace GalleryButton {
  export interface Props {
    text: string;
    type: string;
    onClick: (event:React.SyntheticEvent) => void;
  }
}

export default function GalleryButton (props:GalleryButton.Props) {
  return (
    <button
      className={`galleryButton galleryButton--${props.type}`}
      onClick={props.onClick}
    >
      <span className="galleryButton__text">{props.text}</span>
    </button>
  )
};