import * as React from 'react';
import Button from '../form/button';

export interface SignUpSuccessProps {
  userSignedUp?: boolean;
  onReturn: () => void;
}

const renderResponse = (userSignedUp: boolean) => (
  userSignedUp
    ? <p>Heel fijn om te horen dat jullie komen! We hebben het genoteerd. Komende tijd zullen we jullie nog enkele keren op de hoogte houden, via deze website.</p>
    : <p>Wat jammer dat jullie niet kunnen komen. Toch bedankt dat jullie wat hebben laten horen, en jullie zijn ook welkom een andere keer bij ons op La Ripatte!</p>
);

export default function SignUpSuccess(props: SignUpSuccessProps) {
  return (
    <div>
      <h3>Bedankt voor jullie reactie</h3>
      {renderResponse(props.userSignedUp!)}
      <p>
        Wij wensen jullie allemaal heel fijne kerstdagen toe en een gelukkig en gezond 2019!
      </p>
      <p className="heading">
        Liefs van Jan en Geertje
      </p>
      <Button handleClick={props.onReturn} label="Terug naar het formulier" />
    </div>
  );
}
