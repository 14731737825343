import './sign-up.scss';
import * as React from 'react';
import axios from 'axios';
import SignUpForm from './signup/sign-up-form';
import SignUpSuccess from './signup/sign-up-success';
import SignUpError from './signup/sign-up-error';
import googleAnalyticsService from '../services/google-analytics.service';

export interface SignUpProps {

}

export interface SignUpState {
  phase: 'form'|'success'|'error';
  userSignedUp?: boolean;
  errorMessage?: string;
}

export default class SignUp extends React.Component<SignUpProps, SignUpState> {
  public state: SignUpState = {
    phase: 'form',
    userSignedUp: undefined,
    errorMessage: undefined,
  };

  public render = () => (
    <section className="signUp">
      <section className="signUp__main">
        { this.state.phase === 'form' && <SignUpForm onSubmit={this.formSubmit} /> }
        { this.state.phase === 'success' && <SignUpSuccess userSignedUp={this.state.userSignedUp} onReturn={this.backToForm} /> }
        { this.state.phase === 'error' && <SignUpError errorMessage={this.state.errorMessage!} onReturn={this.backToForm}/> }
      </section>
      <aside className="signUp__aside"></aside>
    </section>
  );

  private formSubmit = async (action: string, formData: object, userSignedUp: boolean) => {
    googleAnalyticsService.sendEvent('sign up', 'submit', `signedUp: ${userSignedUp}`);

    try {
      const result = await axios.post(action, formData);
      if (result.status === 200) {
        googleAnalyticsService.sendEvent('sign up', 'submit - success', `signedUp: ${userSignedUp}`);

        this.setState({
          phase: 'success',
          userSignedUp
        });
      } else {
        googleAnalyticsService.sendEvent('sign up', 'submit - server error', `signedUp: ${userSignedUp}, message: "${result.data.message}"`);

        this.setState({
          phase: 'error',
          errorMessage: `${result.data.message}`,
        });
      }
    } catch(e) {
      googleAnalyticsService.sendEvent('sign up', 'submit - network error', `signedUp: ${userSignedUp}`);

      this.setState({
        phase: 'error',
        errorMessage: `${e.message}. This is all we know. It could be a CORS issue or a dropped internet connection. We cannot be sure.`
      });
    }
    this.state
  };

  private backToForm = () => {
    googleAnalyticsService.sendEvent('sign up', 'back to form', `from: ${this.state.phase}`);
    this.setState({
      phase: 'form',
      errorMessage: undefined,
      userSignedUp: undefined,
    });
  }
}
