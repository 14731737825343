import './gallery-modal.scss';
import React from 'react';
import ReactModal from 'react-modal';
import { Gallery } from '../gallery';
import GalleryButton from './gallery-button';
import GalleryPicture from './gallery-picture';
import { get, includes } from 'lodash';

export namespace GalleryModal {
  export interface Props {
    isShowing: boolean;
    image: Gallery.Image;
    onRequestClose: (trigger: string) => void;
    onShowNext: (trigger: string) => void;
    onShowPrev: (trigger: string) => void;
  }

  export interface State {
    width: number;
    height: number;
  }
}

export default class GalleryModal extends React.PureComponent<GalleryModal.Props, GalleryModal.State> {
  constructor(props:GalleryModal.Props) {
    super(props);
    this.sizer = React.createRef();
    this.state = {
      width: 600,
      height: 400,
    };
  }

  public componentDidUpdate = () => {
    this.updateScale();
  }

  public componentDidMount = () => {
    window.addEventListener('resize', this.updateScale, false);
    window.addEventListener('keyup', this.keyUpHandler, false);
    Promise.resolve().then(this.updateScale);
  };

  public componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateScale);
    window.removeEventListener('keyup', this.keyUpHandler);
  };

  public render = () => (
    <ReactModal
      appElement={document.getElementById('cousinade')!}
      className="galleryModal"
      contentLabel="Cousinade fotogalerij"
      isOpen={this.props.isShowing}
      onRequestClose={this.onRequestClose}
      overlayClassName="galleryOverlay"
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={true}
      style={{
        content: {
          width: `${this.state.width}px`,
          height: `${this.state.height}px`,
        }
      }}
    >
      <GalleryPicture image={this.props.image} />
      <div className="galleryModal__browseButtons">
        <GalleryButton type="next" text="volgende foto" onClick={this.onShowNext} />
        <GalleryButton type="prev" text="vorige foto" onClick={this.onShowPrev} />
      </div>
      <div className="galleryModal__closeButton">
        <GalleryButton type="close" text="sluiten" onClick={this.onRequestClose} />
      </div>
      <div className="galleryModal__sizer" ref={this.sizer} />
    </ReactModal>
  );

  private onShowNext = () => {
    this.props.onShowNext('button');
  };

  private onShowPrev = () => {
    this.props.onShowPrev('button');
  };

  private onRequestClose = (event: React.SyntheticEvent | MouseEvent | KeyboardEvent) => {
    const className = get(event, 'currentTarget.className');
    const trigger = includes(className, 'galleryOverlay')
      ? 'overlay'
      : includes(className, 'galleryButton')
      ? 'button'
      : 'unknown';

    this.props.onRequestClose(trigger);
  };

  private sizer:React.RefObject<HTMLDivElement>;

  private updateScale = () => {
    const { width, height } = this.sizer.current!.getBoundingClientRect();
    const scale = Math.min(
      width / this.props.image.width,
      height / this.props.image.height,
    );
    this.setState({
      width: scale * this.props.image.width,
      height: scale * this.props.image.height,
    });
  };

  private keyUpHandler = (event:KeyboardEvent) => {
    if (event.keyCode === 27) {
      this.props.onRequestClose('key up');
    } else if (event.keyCode === 37) {
      this.props.onShowPrev('key up');
    } else if (event.keyCode === 39) {
      this.props.onShowNext('key up');
    }
  }
}
