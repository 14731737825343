import * as React from 'react';
import './save-the-date.scss';

export default () => 
  <div className="saveTheDate">
    <svg version="1.1" viewBox="0 0 100 100">
      <path className="saveTheDate__path" id="curve" d="
        M  9, 50
        C  9, 28, 28,  9, 50,  9
        C 72,  9, 91, 28, 91, 50
        C 91, 72, 72, 91, 50, 91
        C 28, 91,  9, 72,  9, 50
      " />
      <text className="saveTheDate__border">
        <textPath xlinkHref="#curve">
          Save the Date ★ Save the Date ★ Save the Date ★
        </textPath>
      </text>
      <text className="saveTheDate__main" x="50" y="40" textAnchor="middle">
        30 MEI
      </text>
      <text className="saveTheDate__main saveTheDate__main--small" x="50" y="51" textAnchor="middle">
        tot en met
      </text>
      <text className="saveTheDate__main" x="50" y="66" textAnchor="middle">
        2 juni
      </text>
      <text className="saveTheDate__main saveTheDate__main--small" x="50" y="78" textAnchor="middle">
        2019
      </text>
    </svg>
  </div>;
  