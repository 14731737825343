import './button.scss';
import * as React from 'react';

export interface ButtonProps {
  disabled?: boolean;
  handleClick?: () => void;
  label: string;
  type?: string;
};

export default function Button(props:ButtonProps) {
  return <button
    className="button"
    disabled={props.disabled}
    type={props.type || 'button'}
    onClick={
      (e) => props.handleClick
        ? props.handleClick()
        : false
    }
  >{props.label}</button>;
}
