import './text-input.scss';
import * as React from 'react';
import FormField from './form-field';
import { BaseInputProps } from '../signup/sign-up-form';

export interface TextInputProps extends BaseInputProps {
  handleChange: (name: string, value:string) => void;
  pattern?: string;
  placeholder?: string;
  size?: 'small';
  textAfter?: string;
  type?: string;
};

export default function TextInput(props:TextInputProps) {
  return props.isVisible() ? (
    <FormField
      name={props.name}
      label={props.label}
      required={props.required}
      hasError={props.hasError}
      errorMessage={props.errorMessage}
    >
      <input
        className={props.size
          ? `textInput textInput--${props.size} ${props.hasError ? 'textInput--hasError' : ''}`
          : `textInput ${props.hasError ? 'textInput--hasError' : ''}`
        }
        id={`field-${props.name}`}
        name={props.name}
        onChange={(e) => props.handleChange(props.name, e.target.value)}
        pattern={props.pattern}
        placeholder={props.placeholder}
        required={props.required}
        type={props.type || 'text'}
        value={props.value || ''}
        ref={props.refObj}
      />
      {props.textAfter
        ? <label className="formField__textAfter" htmlFor={`field-${props.name}`}>{props.textAfter}</label>
        : null}
    </FormField>
  ) : null;
}
