import { isEqual } from 'lodash';
import GoogleMapReact, { Coords, ChangeEventValue } from "google-map-react";
import * as React from 'react';
import Marker from "./marker";
import googleAnalyticsService from '../../services/google-analytics.service';

export interface GoogleMapProps {
  center: Coords;
  defaultZoom: number;
}

export default class GoogleMap extends React.Component<GoogleMapProps> {
  constructor(props:GoogleMapProps) {
    super(props);
  }

  public render = () => (
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyD9gb0akN4BuPGmgb92nsTFqUmqI1Ayjv8'}}
      defaultCenter={this.props.center}
      defaultZoom={this.props.defaultZoom}
      onChange={this.onChange}
      options={{
        mapTypeControl: true
      }}
    >
      <Marker lat={this.props.center.lat} lng={this.props.center.lng}>
        La Ripatte
      </Marker>
    </GoogleMapReact>
  );

  private currentSettings?: ChangeEventValue;

  private onChange = (newSettings:ChangeEventValue) => {
    if (this.currentSettings) {
      let changes: string[] = [];
      if (this.currentSettings.zoom !== newSettings.zoom) {
        changes = [ ...changes, `zoom: ${newSettings.zoom}` ];
      }
      if (!isEqual(this.currentSettings.center, newSettings.center)) {
        changes = [ ...changes, 'center: true' ];
      }

      googleAnalyticsService.sendEvent('map', 'interact', changes.join('; '));
    }

    this.currentSettings = newSettings;
  };
}
