import './gallery-picture.scss';
import React from 'react';
import { Gallery } from '../gallery';

export namespace GalleryPicture {
  export interface Props {
    image: Gallery.Image;
  }
}

export default function GalleryPicture(props:GalleryPicture.Props) {
  return (
    <div className="galleryPicture">
      <img className="galleryPicture__img" src={props.image.fullsize} />
    </div>
  );
}
