import './form-text.scss';
import * as React from 'react';

export interface FormTextProps {
  children: React.ReactNode;
  label: string;
  visible: boolean;
};

export default function FormText(props: FormTextProps) {
  return props.visible ? (
    <div className="formText">
      <h4 className="formText__label">
        {props.label}:
      </h4>
      <div className="formText__content">
        {props.children}
      </div>
    </div>
  ) : null;
}
