import './header.scss';
import * as React from 'react';
import SaveTheDate from './misc/save-the-date';

export default () => (
  <header className="header">
    <div className="header__content">
      <h1 className="header__text">
        Bienvenue à...
        <span className="header__headline">
          la Cousinade <span className="header__laripatte">à "La Ripatte"!</span>
        </span>
      </h1>
      <SaveTheDate />
    </div>
  </header>
);