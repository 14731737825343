import './checkbox.scss';
import * as React from 'react';
import { BaseInputProps } from '../signup/sign-up-form';

export interface CheckboxProps extends BaseInputProps {
  handleChange: (name: string, value:string) => void;
};

export interface CheckboxInput {
  label: string;
  value: string;
}

export default function Checkbox(props: CheckboxProps) {
  return props.isVisible() ? (
    <div className="checkbox">
      <input
        className="checkbox__input"
        id={`field-${props.name}`}
        type="checkbox"
        name={props.name}
        checked={props.value === 'ja'}
        onChange={(e) => props.handleChange(props.name, props.value === 'ja' ? 'nee' : 'ja')}
        required={props.required}
        ref={props.refObj}
      />
      <label
        className="checkbox__label"
        htmlFor={`field-${props.name}`}
      >
        {props.label}
      </label>
    </div>
  ) : null;
}
