import './photo-thumb.scss';
import * as React from 'react';

export interface PhotoThumbProps {
  url: string;
  plus?: number;
  href: string;
  analytics?: () => void;
}

export default (props: PhotoThumbProps) => (
  <a className="photoThumb" href={props.href} onClick={props.analytics}>
    <img className="photoThumb__img" src={props.url} />
    {props.plus
      ? <div className="photoThumb__plus">
          <div className="photoThumb__plusText">+{props.plus}</div>
        </div>
      : null}
  </a>
);
