import './updates.scss';
import * as React from 'react';
import Photo from './gallery/photo';
import googleAnalyticsService from '../services/google-analytics.service';

export default () =>
  <section className="newsUpdates">
    <article className="newsUpdates__article newsUpdate">
      <h2 className="newsUpdate__heading">
        Nieuws-update van: <time className="newsUpdate__headingDate">zaterdag 15 december 2018</time>
      </h2>
      <h3>Lieve familie,</h3>
      <p>Het is alweer even stil geweest t.a.v. de Cousinade, maar zoals jullie zien, hebben we niet stil gezeten! De organisatie is al helemaal in gang gezet voor een massale opkomst van jullie op 30 mei t/m 2 juni 2019 bij ons in La Frette.</p>
      <p>We hebben dus onze broer en zussen met echtgenotes(n) en hun kinderen en kleinkinderen, ongeveer 45 volwassenen en 15 kinderen uitgenodigd voor een lang weekend!.</p>
      <p>We vieren dan vooral, dat we een ‘Gezellige Familie bij Elkaar’ zijn! En natuurlijk ook,  dat wij 75 en 74 worden ! En op de 1e juni viert Friso hier zijn 11e verjaardag! Samen dus 160 jaar!  We hopen, dat het een heerlijk feest gaat worden met een stralende zon!</p>
      <p>Om jullie in de aanloop naar de Cousinade te informeren en hopelijk ook enthousiast te maken, hebben Erik en Ting deze mooie website gemaakt. Jullie kunnen ook via deze site laten weten of jullie komen.</p>
      <p>We houden jullie op de hoogte,</p>
      <p className="heading">Hartelijke groeten, Jan &amp; Geertje</p>
    </article>
    <aside className="newsUpdates__aside">
      <Photo
        src="./images/gallery/thumb/photo-0030.jpg"
        href="#/fotogalerij/foto-30"
        analytics={googleAnalyticsService.getSendEventFunction('updates', 'click photo', 'jan + geertje')}
      />
    </aside>
  </section>;


