import './form-field.scss';
import * as React from 'react';

export interface FormFieldProps {
  children: React.ReactNode;
  errorMessage: string;
  hasError: boolean;
  label: string;
  name: string;
  required: boolean;
};

export default function FormField(props: FormFieldProps) {
  return <div className={`formField ${props.hasError ? 'formField--hasError' : ''}`}>
    <label
      className="formField__label"
      htmlFor={`field-${props.name}`}
    >
      {props.label}:
      {props.required && <span className="formField__required"> *</span>}
    </label>
    <div className="formField__inputContainer">
      {props.children}
    </div>
    {props.hasError && <div className="formField__errorMessage">{props.errorMessage}</div>}
  </div>;
}