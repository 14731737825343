import './main.scss';
import * as React from 'react';
import Updates from './updates';
import LaCousinade from './la-cousinade';
import AroundLaFrette from './around-la-frette';
import SignUp from './sign-up';
import Gallery from "./gallery";
import Location from './location';

export default () =>
  <main className="main">
    <Updates/>
    <LaCousinade/>
    <Gallery/>
    <AroundLaFrette/>
    <SignUp />
    <Location />
  </main>;
