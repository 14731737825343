import './text-and-image.scss';
import * as React from 'react';
import { capitalize } from 'lodash';

export interface TextAndImageProps {
  children: React.ReactNode;
  imageSide: 'left'|'right';
  imageUrl: string;
}

export default (props: TextAndImageProps) => (
  <section className={`textAndImage textAndImage--image${capitalize(props.imageSide)}`}>
    <div className="textAndImage__imageContainer">
      <div className="textAndImage__imageWrapper">
        <img className="textAndImage__image" src={props.imageUrl} />
      </div>
    </div>
    <div className="textAndImage__text">{props.children}</div>
  </section>
);
