import { get, noop } from 'lodash';

export class GoogleAnalyticsService {
  constructor() {
    this.gtag = get(window, 'gtag', noop);
    this.gtag('config', 'UA-131082395-1', { 'send_page_view': false });
  }

  public pageview = () => {
    const location = document.location!;
    const path = location.pathname;
    const hash = location.hash.replace(/^#\/?/, '');
    this.gtag('config', 'UA-131082395-1', {
      page_path: `${path}${hash}`
    });
  };

  public sendEvent = (category: string, action: string, label?: string, value?: number) => {
    this.gtag('event', action, {
      'event_category': category,
      'event_label': label,
      'value': value
    });
    // console.log('sent GA event', category, action, label, value);
  };

  public getSendEventFunction = (category: string, action: string, label?: string, value?: number) => {
    return () => {
      this.sendEvent(category, action, label, value);
    }
  };

  private gtag: (...rest:any[]) => void;
}

export default new GoogleAnalyticsService();
