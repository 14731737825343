import './photo.scss';
import * as React from 'react';

export interface PhotoProps {
  href?: string;
  src: string;
  variation?: 'cover';
  extraClass?: string;
  analytics?: () => void;
}

export default function Photo(props:PhotoProps) {
  const variation = props.variation ? props.variation : 'base';
  return props.href
    ? (
      <a
        className={`photo photo--link photo--${variation} ${props.extraClass || ''}`}
        href={props.href}
        onClick={props.analytics}
      >
        <img className="photo__img" src={props.src} />
      </a>
    )
    : (
      <div className={`photo photo--${variation} ${props.extraClass || ''}`}>
        <img className="photo__img" src={props.src} />
      </div>
    )
}