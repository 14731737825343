import './around-la-frette.scss';
import * as React from 'react';
import TextAndImage from './misc/text-and-image';

export default () => (
  <section className="aroundLaFrette">
    <article className="aroundLaFrette__article">
      <h2>Wat is er te doen?</h2>
      <p>We hopen, dat we mooi weer zullen hebben dat weekend. Dan is het heerlijk toeven met ons allen onder de bomen aan de voorkant of in de achtertuin. Het zal fijn zijn elkaar weer te ontmoeten, veel bij te praten en gezamenlijk te eten! Voor ontbijt, lunches en warme maaltijden enz. wordt gezorgd. Voor de kleinkinderen zal het vast leuk zijn, hun nichtjes en neefjes beter te leren kennen. We hebben als het nodig is,  leuke ideeën, om het een en ander te organiseren voor en met hen!</p>
      <p>Daarnaast zijn er nog een aantal andere interessante excursies te organiseren als daar belangstelling voor is. Je zou dan kunnen denken aan de volgende zaken:</p>
      <TextAndImage imageUrl="./images/hotel-dieu.jpg" imageSide="right">
        <p>Een <b>stadswandeling door Louhans</b>, met daarbij een bezoek aan ‘Hôtel Dieu’. Een zeer interessant gebouw dat tegelijkertijd een museum, apotheek en ziekenhuis is.</p>
        <p>Meer informatie over Hôtel Dieu vinden jullie hier: <a href="http://www.hotel-dieu-louhans.fr/">http://www.hotel-dieu-louhans.fr/</a>.</p>
      </TextAndImage>
      <TextAndImage imageUrl="./images/gallery/thumb/photo-0072.jpg" imageSide="left">
        <p>We kunnen ook <b>mooie landelijke wandelingen</b> maken in onze omgeving. Gewoon te beginnen vanuit ons huis. Je kan al leuk wandelen over zandpaden voor een half uurtje. Maar ook grotere wandelingen van een uur of langer in de nabije omgeving zijn geen probleem. Uitnodigende kleine wegen laten je dan veel van de omgeving zien. Weilanden met koeien en paarden bijvoorbeeld, we kunnen begin juni ook genieten van graanvelden met korenbloemen en klaprozen. Aan de kant van de weg zullen tussen het gras ook veel bloemen groeien, want er is nog niet gemaaid. Ook passeren we dan kleine bosgebieden.</p>
      </TextAndImage>
      <TextAndImage imageUrl="./images/rondom-lafrette/tournus.jpg" imageSide="right">
        <p>Voor de <b>wijn-liefhebbers</b> onder jullie: Tournus (ongeveer 20 km van La Frette) heeft een heel goede cave om wijn te proeven en te kopen uiteraard. Tournus is verder een heel interessant oud stadje om te bezoeken, bijvoorbeeld ook de moeite waard daar is de oude Abdijkerk de Philibert.</p>
        <p>Meer weten? Hier vind je de website van de cave: <a href="https://www.lesvigneronsdemancey.fr/">https://www.lesvigneronsdemancey.fr</a>.</p>
      </TextAndImage>
    </article>
  </section>
);

