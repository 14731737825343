import './location.scss';
import * as React from 'react';
import GoogleMap from './map/google-map';

export default function Location() {
  return (
    <section className="location">
      <div className="location__map">
        <GoogleMap
          center={{ lat: 46.64105, lng: 5.06358 }}
          defaultZoom={4}
        />
      </div>
      <div className="location__address">
        <h3>Adresinformatie</h3>
        <p>De cousinade vindt plaats van 30 mei tot en met 2 juni 2019 bij ons:</p>
        <p className="heading">
          Jan en Geertje Hagreis<br/>
          771, rue La Ripatte<br/>
          Le Bois Philibert,<br/>
          71440 La Frette,<br/>
          France
        </p>
        <p>
          latitude: <span className="heading">46.6407</span>, longitude: <span className="heading">5.0641</span>
        </p>
      </div>
    </section>
  );
}
