import './gallery.scss';
import * as React from 'react';
import axios from 'axios';
import { get } from 'lodash';
import wrapNumber from '../utils/wrap-number';
import PhotoGrid from './gallery/photo-grid';
import GalleryModal from './gallery/gallery-modal';
import googleAnalyticsService from '../services/google-analytics.service';

export namespace Gallery {
  export interface Props {
    dataUrl?: string;
  }

  export interface State {
    dataReady: boolean;
    modalShowing: boolean;
    modalImageIndex: number;
    imageList: Image[];
  }

  export interface Image {
    fileName: string;
    thumb: string;
    fullsize: string;
    orientation: string;
    width: number;
    height: number;
    galleryUrl:string;
  }
}

export default class Gallery extends React.Component<Gallery.Props, Gallery.State> {
  constructor(props:Gallery.Props) {
    super(props);
    this.init();
  };

  state = {
    dataReady: false,
    imageList: [],
    modalImageIndex: -1,
    modalShowing: false,
  };

  init = async () => {
    this.setState({
      dataReady: true,
      imageList: await this.loadGalleryData(),
    });
    this.processUrl(document.location!.href);
  };

  private loadGalleryData = async () => {
    const url = this.props.dataUrl || './images/gallery/gallery.json';
    const response = await axios.get(url);

    return (response.data as Gallery.Image[]).map((image, index) => {
      return {
        ...image,
        galleryUrl: `#/fotogalerij/foto-${index + 1}`
      }
    });
  };

  private hashChangeHandler = (event: HashChangeEvent) => {
    this.processUrl(event.newURL);
    googleAnalyticsService.pageview();
  };

  private processUrl = (url:string) => {
    const [ , match1 ]: string[] = /#\/fotogalerij\/foto-(\d+)/.exec(url) || [];
    if (match1) {
      const imageNumber = parseInt(match1, 10);
      this.modalOpen(imageNumber - 1);
    } else {
      this.modalClose();
    }
  };

  private modalOpen = (modalImageIndex:number) => {
    this.setState({
      modalImageIndex,
      modalShowing: true
    })
  };

  private modalClose = () => {
    this.setState({
      modalShowing: false,
    });
  };

  private modalShowNext = (trigger: string) => {
    googleAnalyticsService.sendEvent('gallery modal', 'show next', `trigger: "${trigger}"`);

    const nextIndex = wrapNumber(this.state.modalImageIndex + 1, this.state.imageList.length - 1);
    const nextUrl = get(this.state, `imageList.${nextIndex}.galleryUrl`);
    document.location!.href = nextUrl;
  };

  private modalShowPrev = (trigger: string) => {
    googleAnalyticsService.sendEvent('gallery modal', 'show prev', `trigger: "${trigger}"`);

    const nextIndex = wrapNumber(this.state.modalImageIndex - 1, this.state.imageList.length - 1);
    const nextUrl = get(this.state, `imageList.${nextIndex}.galleryUrl`);
    document.location!.href = nextUrl;
  };

  private modalRequestClose = (trigger: string) => {
    googleAnalyticsService.sendEvent('gallery modal', 'close', `trigger: "${trigger}"`);

    document.location!.href = '#';
  };

  render = () => (
    <section className="gallery">
      {this.state.dataReady &&
        <PhotoGrid
          imageList={this.state.imageList}
          featuredPhotoCount={9}
        />
      }
      {this.state.modalShowing &&
        <GalleryModal
          image={this.state.imageList[this.state.modalImageIndex]}
          isShowing={this.state.modalShowing}
          onRequestClose={this.modalRequestClose}
          onShowNext={this.modalShowNext}
          onShowPrev={this.modalShowPrev}
        />
      }
    </section>
  );

  componentDidMount = () => {
    window.addEventListener('hashchange', this.hashChangeHandler, false);
  };

  componentWillUnmount = () => {
    window.removeEventListener('hashchange', this.hashChangeHandler);
  };
}
