import * as React from 'react';
import Button from '../form/button';

export interface SignUpErrorProps {
  errorMessage: string;
  onReturn: () => void;
}

export default function SignUpError(props: SignUpErrorProps) {
  return (
    <div>
      <h3>Oups!</h3>
      <p>
        Dit is <em>très gênant</em>: er ging iets mis bij het verwerken van je reactie. Het probleem is vastgelegd en we gaan werken aan een oplossing. Probeer het later nog eens!
      </p>
      <p>
        Mocht het steeds niet lukken, stuur dan <a href="mailto:erik.hagreis@gmail.com">erik.hagreis@gmail.com</a> een berichtje. Alvast bedankt!
      </p>
      <p>
        Dit is wat de server zei:
        <code className="serverErrorMessage">{props.errorMessage}</code>
      </p>
      <Button handleClick={props.onReturn} label="Terug naar het formulier" />
    </div>
  );
}
