import './sign-up-form.scss';
import * as React from 'react';
import { get, keys, mapValues, pickBy, size } from 'lodash';
import TextInput from '../form/text-input';
import RadioGroup from '../form/radio-group';
import FormButton from '../form/button';
import TextArea from '../form/text-area';
import googleAnalyticsService from '../../services/google-analytics.service';
import Checkbox from '../form/checkbox';
import FormText from '../form/form-text';

export interface SignUpFormProps {
  onSubmit: (action: string, data: object, userIsAffirming: boolean) => void;
}

export interface BaseInputProps {
  isVisible: () => boolean;
  errorMessage: string;
  hasError: boolean;
  label: string;
  name: string;
  refObj: React.RefObject<any>;
  required: boolean;
  value: string;
}

export interface SignUpFormState {
  submitting: boolean;
  data: {
    [ key: string ]: BaseInputProps,
  }
}

export default class SignUpForm extends React.Component<SignUpFormProps, SignUpFormState> {
  public userIsAffirming = () => /ja/i.test(this.state.data.wijKomen.value);

  public userIsSigningUpForWhatsapp = () => /ja/i.test(this.state.data.whatsapp.value)

  public state = {
    submitting: false,
    data: {
      naam: {
        name: 'naam',
        label: 'Wij zijn de familie',
        required: true,
        isVisible: () => true,
        ...createBaseInputProps()
      },
      wijKomen: {
        label: "En wij komen",
        name: "wijKomen",
        required: true,
        isVisible: () => true,
        ...createBaseInputProps(),
      },
      email: {
        label: "Je kan ons op dit e-mailadres bereiken",
        name: "email",
        required: true,
        type: "email",
        isVisible: this.userIsAffirming,
        ...createBaseInputProps(),
      },
      personen: {
        label: "Wij nemen",
        name: "personen",
        required: true,
        isVisible: this.userIsAffirming,
        ...createBaseInputProps(),
      },
      overnachting: {
        label: "Wij overnachten zo",
        name: "overnachting",
        required: true,
        isVisible: this.userIsAffirming,
        ...createBaseInputProps(),
      },
      whatsapp: {
        label: "ja, schrijf ons in voor de Cousinade whatsappgroep",
        name: "whatsapp",
        required: false,
        isVisible: this.userIsAffirming,
        ...createBaseInputProps(),
      },
      telefoon: {
        label: "Dit zijn onze telefoonnummer(s)",
        name: "telefoon",
        required: false,
        isVisible: () => (this.userIsAffirming() && this.userIsSigningUpForWhatsapp()),
        ...createBaseInputProps(),
      },
      opmerkingen: {
        label: "Onze eventuele opmerkingen zijn",
        name: "opmerkingen",
        required: false,
        isVisible: () => true,
        ...createBaseInputProps(),
      },
    }
  };

  public componentDidMount() {
    this.setState({
      submitting: false
    });
  }

  public render() {
    return <form
      action={this.action}
      className="signUpForm"
      noValidate
      onSubmit={this.handleFormSubmit}
    >
      <h2>Komen jullie ook?</h2>
      <p>Laat svp hier weten of jullie komen. Dan weten we hoeveel paella Geertje moet koken ;)</p>
      <TextInput
        {...this.state.data.naam}
        handleChange={this.handleInputChange}
      />
      <RadioGroup
        {... this.state.data.wijKomen}
        handleChange={this.handleInputChange}
        options={[{
          label: 'heel graag!',
          value: 'ja! :)'
        }, {
          label: 'helaas niet...',
          value: 'nee :('
        }]}
      />
      <TextInput
        {... this.state.data.email}
        handleChange={this.handleInputChange}
      />
      <TextInput
        {... this.state.data.personen}
        handleChange={this.handleInputChange}
        size="small"
        pattern='\d+'
        textAfter="personen mee"
      />
      <TextInput
        {... this.state.data.overnachting}
        handleChange={this.handleInputChange}
        placeholder="bv: in een tent, camper, caravan, hotel..."
      />
      <FormText
        label="Whatsapp-groep"
        visible={this.userIsAffirming()}
      >
        <p>
          Om makkelijk informatie uit te kunnen wisselen in de aanloop naar de Cousinade, nodigen we je uit om deel te nemen in onze Whatsapp groep. Je kan hier aangeven of je hieraan toegevoegd wil worden.
        </p>
      </FormText>
      <Checkbox
        {... this.state.data.whatsapp}
        handleChange={this.handleInputChange}
      />
      <TextArea
        {... this.state.data.telefoon}
        handleChange={this.handleInputChange}
        placeholder="vul liefst duidelijk in van wie welk nummer is."
        size="small"
      />
      <TextArea
        {... this.state.data.opmerkingen}
        handleChange={this.handleInputChange}
      />
      <FormButton
        disabled={this.state.submitting}
        label="verstuur"
        type="submit"
      />
    </form>
  };

  private action = 'https://cvncasbjtj.execute-api.eu-central-1.amazonaws.com/default/cousinade-laripatte-signup';

  private handleInputChange = (name: string, value: string) => {
    this.setState((prevState) => {
      const inputProps = prevState.data[ name ];
      const updated = inputProps.hasError ? updateValidity(inputProps) : inputProps;
      return {
        ...prevState,
        data: {
          ...prevState.data,
          [ name ]: {
            ...updated,
            value,
          }
        }
      };
    });
  };

  private validateForm = () => {
    const newInputProps = mapValues(this.state.data, updateValidity);
    this.setState((prevState) => ({
      ...prevState,
      data: newInputProps,
    }));

    const inputsWithError = pickBy(newInputProps, 'hasError');
    if (size(inputsWithError) > 0) {
      const errorSummary = keys(inputsWithError).join(',');
      googleAnalyticsService.sendEvent('sign up', 'submit - validation error', `fields: "${errorSummary}"`);
      return false;
    }
    return true;
  };

  private getValues = () => {
    const visibleItems = pickBy(this.state.data, (props) => props.isVisible());
    return mapValues(visibleItems, 'value');
  };

  private handleFormSubmit = async (event:React.SyntheticEvent) => {
    event.preventDefault();
    if (this.validateForm()) {
      this.setState({
        submitting: true
      });

      this.props.onSubmit(this.action, this.getValues(), this.userIsAffirming());
    }
  };
}

function createBaseInputProps() {
  return {
    value: '',
    hasError: false,
    errorMessage: '',
    refObj: React.createRef<any>(),
  }
}

function updateValidity(inputProps: BaseInputProps) {
  const validity: ValidityState = get(inputProps, 'refObj.current.validity');
  if (!validity || validity.valid) {
    return {
      ...inputProps,
      hasError: false,
      errorMessage: ''
    };
  }
  return {
    ...inputProps,
    hasError: true,
    errorMessage:
      validity.valueMissing
      ? 'Dit veld is verplicht'
      : validity.typeMismatch
      ? 'Dit moet een e-mailadres zijn'
      : 'Verkeerde invoer',
  }
}
