import './radio-group.scss';
import * as React from 'react';
import FormField from  './form-field'
import { BaseInputProps } from '../signup/sign-up-form';

export interface RadioGroupProps extends BaseInputProps {
  handleChange: (name: string, value:string) => void;
  options: FormRadioOption[];
};

export interface FormRadioOption {
  label: string;
  value: string;
}

export default function RadioGroup(props: RadioGroupProps) {
  return props.isVisible() ? (
    <FormField
      name={`${props.name}-0`}
      label={props.label}
      required={props.required}
      hasError={props.hasError}
      errorMessage={props.errorMessage}
    >
      <div className="radioGroup">
        {props.options.map((option, index) => (
          <div
            key={`radio-${props.name}-${index}`}
            className={`radioGroup__radio radioOption ${props.hasError ? 'radioOption--error' : ''}`}
          >
            <input
              className="radioOption__input"
              id={`field-${props.name}-${index}`}
              type="radio"
              name={props.name}
              value={option.value}
              checked={props.value === option.value}
              onChange={(e) => props.handleChange(props.name, e.target.value)}
              required={props.required}
              ref={index === 0 ? props.refObj : null}
            />
            <label
              className="radioOption__label"
              htmlFor={`field-${props.name}-${index}`}
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </FormField>
  ) : null;
}
