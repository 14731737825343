import './photo-grid.scss';
import * as React from 'react';
import { map, take } from 'lodash';
import PhotoThumb from './photo-thumb';
import { Gallery } from '../gallery';
import googleAnalyticsService from '../../services/google-analytics.service';

export interface PhotoGridProps {
  imageList: Gallery.Image[];
  featuredPhotoCount: number;
}

export default function PhotoGrid (props: PhotoGridProps) {
  const featuredPhotoCount = props.featuredPhotoCount
  const totalPhotoCount = props.imageList.length;
  const featuredSet: Gallery.Image[] = take(props.imageList, featuredPhotoCount);
  const extraPhotosCount = Math.max(0, totalPhotoCount - featuredPhotoCount);

  return <section className="photoGrid">
    {map(featuredSet, (image: Gallery.Image, index) => {
      const isLastPhoto = index === featuredSet.length - 1;

      return <div key={`photo_${index}`} className="photoGrid__thumbContainer">
        <div className="photoGrid__thumb">
          <PhotoThumb
            url={image.thumb}
            plus={isLastPhoto ? extraPhotosCount : undefined}
            href={image.galleryUrl}
            analytics={googleAnalyticsService.getSendEventFunction('photo grid', 'click photo', `photo ${index}`)}
          />
        </div>
      </div>;
    })}
  </section>;
}

